.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
}

.male {
  fill: rgb(22, 130, 218);
}

.female {
  fill: rgb(246, 0, 0);
}

.neutral {
  fill: rgb(100, 100, 100);
}

.icon {
  fill: rgb(255, 255, 255);
}

/* .info {
  fill: rgb(153, 153, 153);
} */

.linklabel {
  fill: rgb(155,155,155);
}

.node text {
  font-family: sans-serif;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}

#optionsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #d502ae;
}

#optionsBar {
  position: absolute;
  margin: auto;
  top: 20px;
}

#helpOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.inputContainer {
  width: 100%;
  /* position: absolute;
  top: 20px;
  margin: 0px 20px; */
  /* position: absolute;
  top: 20px;
  padding: 0px; */
}

#modeDisplay {
  /* color: #9e00a0; */
  font-weight: bold;
  /* position: absolute;
  top: 22px;
  left: 20px; */
}

#infoDisplay {
  color: #363b46;
  /* position: absolute;
  top: 22px;
  right: 20px; */
}

.inputField {
  width: 250px;
}

.inputButton {
  width: 60px;
  height: 21.5px;
}

.contextMenu {
  stroke: #00344d;
  /* fill: #ffffff; */
}

.clickable {
  cursor: pointer;
}

.menuEntry {
  padding: 0;
}

.menuEntry text {
  font-size: 12px;
  stroke: #fff;
  /* stroke: #00557d; */
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.footerText {
  color: #4b4b4b;
  margin-left: 20px;
  font-weight: bold;
}

.footerText:hover {
  color: darkgrey;
}